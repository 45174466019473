<template>
  <v-app class="grey lighten-4">
    <section class="py-5 h-100 d-flex justify-content-center main-div-login">
      <v-card
        elevation="2"
        outlined
        :class="webbreakdown() ? 'w-100' : 'w-75'"
        class="m-auto login-subdiv"
      >
        <v-card-text class="main-content-div">
          <v-row class="w-100 m-0">
            <v-col cols="12" sm="12" lg="6" class="image-div">
              <h2 class="text-primary-color">{{ $t("appName") }}</h2>
              <br />
              <img
                alt="QRate"
                class="logo_img_lg w-100 h-75"
                src="../../assets/Forgot_password.png"
                style="max-height: 75% !important"
              />
            </v-col>

            <v-col cols="12" sm="12" lg="6" class="pb-2 login-main-div">
              <v-form v-model="valid" ref="form">
                <div class="w-100 m-auto p-2">
                  <h2
                    class="text-center text-primary-color header-main-div font-weight-bold"
                  >
                    {{ $t("forgotYourPassword") }}
                  </h2>
                  <loginTypeVue @selectedLogin="selectedLogin" />
                  <br />

                  <v-alert
                    v-if="alertMsg.status"
                    :timeout="alertMsg.timeout"
                    :color="alertMsg.color"
                    :type="alertMsg.type"
                    dismissible
                  >
                    {{ alertMsg.text }}
                  </v-alert>

                  <label class="text-midgray-color">{{
                    $t("confirm_mobile_num")
                  }}</label>
                  <v-select
                    v-model="user_details.user_type"
                    :items="user_types"
                    :label="$t('user_type')"
                    :placeholder="$t('user_type_placeholder')"
                    item-text="txt"
                    item-value="val"
                    :rules="user_type_rules"
                  >
                    <template v-slot:prepend-inner class="pt-0">
                      <v-icon class="me-4 pb-2 font-size">{{
                        user_details.user_type === "IND"
                          ? "mdi-account-tie"
                          : "mdi-briefcase-account"
                      }}</v-icon>
                    </template></v-select
                  >
                  <v-text-field
                    v-if="login_type === 'mobile'"
                    type="number"
                    hide-spin-buttons
                    id="sendAmount"
                    v-model="user_details.mobile_number"
                    :hint="'+60 xx xxx xxxx'"
                    color="primary"
                    :placeholder="$t('mobile_number_placeholder')"
                    required
                    prefix="+60"
                    :rules="mobileRules"
                    class="custome-text-field"
                    :label="$t('mobile_number')"
                    @keydown="enterKeyPressed"
                  >
                    <template v-slot:prepend-inner class="pt-0">
                      <country-flag country="my" class="mb-0 me-2" />
                    </template>
                    <template v-slot:append>
                      <v-icon color="green">mdi-arrow-right</v-icon>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-else
                    v-model="user_details.email"
                    :label="$t('emailID')"
                    :placeholder="$t('email_placeholder')"
                    :rules="emailValidations"
                    clearable
                    append-icon="mdi-account-outline"
                  ></v-text-field>

                  <v-btn
                    block
                    rounded
                    color="primary"
                    @click="onSubmitForgot"
                    class="btn-primary mt-3 text-capitalize"
                  >
                    <div class="button-loader" v-if="loader">
                      <span class="spinner-border spinner-border-sm"></span>
                    </div>
                    <div v-else>{{ $t("sendOtp") }}</div>
                  </v-btn>

                  <div
                    class="text-dark mt-4 d-flex justify-content-center align-items-baseline"
                  >
                    <span>{{ $t("back_to") }}</span>
                    <v-btn
                      class="text-capitalize text-primary-color p-2"
                      color="primary"
                      plain
                      @click="$router.push('/login')"
                    >
                      {{ $t("loginPage") }}?
                    </v-btn>
                  </div>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </section>
  </v-app>
</template>

<script>
import { webbreakdown } from "../../utils/breakpoints";
import { returnuserTypes } from "../../utils/constDropdowns/userType";
import { i18n as $ } from "../../locale/index";
import userservice from "@/services/UserService/userservice";
import loginTypeVue from "./loginType.vue";
import alerts from "@/mixins/alerts";
export default {
  name: "ForgotPassword",
  mixins: [alerts],
  data() {
    return {
      webbreakdown,
      login_type: "email",
      user_details: {
        mobile_number: "",
        password: "",
        email: "",
        user_type: "BUS",
      },
      alertMsg: {
        status: false,
        text: "",
        timeout: 2000,
        color: "blue-grey",
        type: "info",
      },
      user_types: returnuserTypes(),
      dismissCountDown: 20,
      loader: false,
      emailValidations: [
        (v) => !!v || $.t("emailMsg1"),
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          $.t("emailMsg2"),
      ],

      mobileRules: [
        (v) => !!v || $.t("mobile_required"),
        (v) => /^(([0-9]*){20})$/.test(v) || $.t("mobile_required"),

        (v) => (v && v.length >= 9) || $.t("min_mobile_number"),
        (v) => (v && v.length <= 12) || $.t("max_mobile_number"),
      ],
      user_type_rules: [(v) => !!v || $.t("usertype_req")],
    };
  },
  components: {
    loginTypeVue,
  },

  methods: {
    selectedLogin(data) {
      this.login_type = data;
    },

    enterKeyPressed(event) {
      if (event.keyCode == 13) this.onSubmitForgot();
    },
    checkNumber(val) {
      if (val.substring(0, 2) === "60") {
        return val;
      } else {
        return "60" + val;
      }
    },
    async onSubmitForgot() {
      if (this.$refs.form.validate()) {
        try {
          this.loader = true;
          var userDetails = {};
          var responseData = [];
          var formated_number = null;
          if (this.login_type == "mobile") {
            formated_number = this.checkNumber(this.user_details.mobile_number);
            userDetails = {
              mobile_number: formated_number,
              user_type: this.user_details.user_type,
            };
            responseData = await userservice.forgotPassword(userDetails);
          } else {
            userDetails = {
              email: this.user_details.email,
              user_type: this.user_details.user_type,
            };
            responseData = await userservice.forgotemailPassword(userDetails);
          }

          if (responseData && responseData.data.success) {
            this.loader = false;
            sessionStorage.setItem(
              "forget_user_type",
              this.user_details.user_type
            );
            sessionStorage.setItem("forget_login_type", this.login_type);
            this.login_type == "mobile"
              ? sessionStorage.setItem("forget_mobile_number", formated_number)
              : sessionStorage.setItem("forget_email", this.user_details.email);

            this.showAlertSuccess(responseData.data.message);
            this.$router.push({
              name: "UpdatePassword",
              params: {
                mobileUser: formated_number,
                email: this.user_details.user_type,
                user_type: this.user_details.user_type,
              },
            });
          } else {
            this.loader = false;
            this.showAlertError(responseData.data.message);
          }
        } catch (error) {
          this.loader = false;
          this.showAlertError(
            "something went wrong please try again sometime!"
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";

.logo_img {
  max-height: 50px;
}

.footer {
  margin-top: 180px !important;
}

.invalid-feedback-email {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 14px;
  background-color: #f5f5f5;
  color: #dc3545;
  border: 1px solid #dee2e6;
}
</style>
